import React from 'react';
import FillerTop from '../components/FillerTop';
import './Section.css';

const Section = ({image, order2, title, children, color}) => (
    <div className="section">
    <FillerTop />
        {!order2 && image ? <img src={image} className="medical-image" /> : null}
        <div className={image ? "text-container" : "noImage"}
            style={{backgroundColor:color}}>
            <p className="title">{title}</p>
            <div className="list-container">
                {children}
            </div>
        </div>
        {order2 && image ? <img src={image} className="medical-image" /> : null}
    </div>
);

export default Section;

