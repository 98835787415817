import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navbar.css';
import LogoNavBar from '../Assets/LogoNavBar.png'

const Navbar = (props) => (
  <div>
    <nav className="nav-wrapper">
      <img src={LogoNavBar} className="nav-logo" alt="logo" />
      <div style={{flexFlow: "column", justifyContent: "space-around", margin: '6px'}}>
        <ul className="nav-link ">
          <li><NavLink exact to="/">Home</NavLink></li>
          <li><NavLink to='/software'>Software</NavLink></li>
          <li><NavLink to='/covid19App'>Covid-19 App</NavLink></li>
          <li><NavLink to='/contact'>Contact Us</NavLink></li>
        </ul>
        </div>
      </nav> 
    </div>
  );

export default Navbar;
