import React from 'react';

import List from '../components/List';
import PhoneMockup1 from '../Assets/PhoneMockup1.png';
import PhoneMockup2 from '../Assets/PhoneMockup2.png';
import FillerTop from '../components/FillerTop';
import Banner from '../components/Banner';
import './Covid19App.css';

const Covid19App = () => (
  <div className="container">
    <FillerTop />
    <Banner title="Covid-19 Daily Symptom Screening App">
      <div className="Covid-button">
      <a className="Covid-a" href="https://www.checkpoint.twinsolutions.co.za" target="_blank">{'Get the App'}</a>
    </div>
    </Banner>
    <div className="row">
      <div>
        <img src={PhoneMockup1}
          alt=""
          className="phone"
        />
      </div>
      <div className="covidApp-body">
        <p>For daily employee Covid screening of employees</p>
        <p>...without a mountain of paper to get you down</p>
        <br />
        <p>Look no further!!!</p>
        <br />
        <p><span className="CheckPoint-text">- CheckPoint -</span> has the answers:</p>
        <br />
        <List
          item1="Easy to use phone or web app"
          item2="Quick to record"
          item3="Safe from Covid droplets"
          item4="Paperless system – no more forms"
          item5="Structured database"
          item6="Environmentally friendly"
          item7="Employees can do screening on their own phone"
          item8="Screening can also be done by screener at security gate"
          item9="Temperature input by screener at security gate"
          item10="Daily and monthly excel reports"
          item11="Weekly symptom reporting to NIOH"
          item12="Compliant with Government Legislation"
        />
      </div>
      <div>
        <img src={PhoneMockup2}
          alt=""
          className="phone"
        />
      </div>
    </div>
  </div>
);

export default Covid19App;
