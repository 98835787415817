import React from 'react';

import FillerImg from '../Assets/FillerImg.jpg';
import './FillerTop.css';

const FillerTop = () => (
    <div>
        <img src={FillerImg} className="filler-top" />
    </div>
);

export default FillerTop;
