import React from 'react';
// import { Link } from 'react-router-dom';

import ClinsysScreens from '../Assets/ClinsysScreens.jpg';
import Banner from '../components/Banner';
import Card from '../components/Card';
import Helmet from '../Assets/Icons/Helmet.png';
import Doctor from '../Assets/Icons/Doctor.png';
import Mobile from '../Assets/Icons/Mobile.png';
import Injury from '../Assets/Icons/Injury.png';
import Covid from '../Assets/Icons/Covid.png';
import Graph from '../Assets/Icons/Graph.png';
import FillerTop from '../components/FillerTop';
import './Home.css';

const Home = () => ( // body margin of nav 62 applies to home but not other paths
    <div style={{ marginTop:"-62px"}}> 
    <FillerTop />
      <img src={ClinsysScreens}
        alt=""
        className="clinsys-screens"
      />
      <Banner
        title='Easy to use, quick to record'
        // title='Easy to use, quick to record. Go paperless with our comprehensive system!'
        title2='Go paperless with our comprehensive system!'
     />
      <>
        <div className="cards-container">
          <Card
            icon={Helmet}
            title='Occupational Health'
            subtitle='Record Medicals and issue Fit Certificates.'
            subtitle2='Audiometry, Lung Functions and Job Exposures included'
            color="var(--blue-3)"
            />
          <Card
            icon={Doctor}
            title='Primary Health'
            subtitle='Record complaints, diagnoses and procedures done.' 
            subtitle2 ='Dispense drugs and issue sick certificates. Track history and write referral letters'
            color="var(--blue-2)"
            />
          <Card
            icon={Mobile}
            title='Covid-19 App'
            subtitle='Record your employees temperature and symptoms at the door'
            subtitle2='Report weekly symptoms to NIOH'
            color="var(--green-1)"
            />
        </div>
        <div className="cards-container">
          <Card
            icon={Injury}
            title='Injury on Duty'
            subtitle='Record Injuries on Duty and Occupational Diseases.'
            subtitle2="Record cause, body part injured and diagnosis"
            color="var(--blue-1)"
          />
          <Card
            icon={Covid}
            title='Covid Module'
            subtitle='Record symptoms, Covid tests and vulnerability.'
            subtitle2='Calculate probability of Covid-19 and issue Covid Fit Certificates'
            color="var(--green-3)"
          />
          <Card
            icon={Graph}
            title='Reports and Graphs'
            subtitle='Produce comprehensive accurate monthly and annual reports, stats and graphs!'
            color="var(--green-2)"
          />
        </div>
      </>
    </div>
  );

export default Home;
