import React from 'react';
import './Banner.css';

const Banner = ({title, title2, children}) => (
    <div className="banner">
        <h1 className="banner-title">{title}</h1>
       {title2 ? <h1 className="banner-title">{title2}</h1> : null}
        <div className="content-container">
        {children}
        </div>
    </div>
);

export default Banner;
