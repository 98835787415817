import React, { Component } from 'react';
import './Footer.css';

const Footer = () => (
    <footer className="footer">
        <ul className="footer-link">
            <div className="footer-link">
                <li>Southern Africa</li>
                <li>|</li>
                <li>+27 84 580 4016</li>
                <li>|</li>
                <li>clinsys@twinsolutions.co.za</li>
                <li>|</li>
                <li><a href="https://www.checkpoint.twinsolutions.co.za" target="_blank">CheckPoint App</a></li>
                {/* <li><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" target="_blank">visit WHO</a></li> */}
                {/* <li><a href="https://www.jozikids.co.za/zaparents-blog/isolation-kit-a-shopping-list-to-help-you-get-through/">visit Josi Kids</a></li> */}
            </div>
        </ul>
    </footer>
);

export default Footer;
