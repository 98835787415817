import React from 'react';

import MedicalBackground from '../components/MedicalBackground';
import ClinsysWhite from '../Assets/ClinsysWhite.png';
import FillerTop from '../components/FillerTop';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact">
      <FillerTop />
      <p className="contact-title">We would love to hear from you...</p>
      <MedicalBackground>
      <div className="contact-form">
        <img className="clinsys-logo" src={ClinsysWhite} alt="icon" height="100"  />
        <div className="contact-text">
          <p>Email:    clinsys@twinsolutions.co.za</p>
          <p>Phone:   + 27 84 580 4016</p>
          <p>              + 27 12 667 3157</p>
          <br />
          <p>PO Box 583, Irene, Pretoria, South Africa</p>
         </div>
      </div>
      </MedicalBackground>
      <div className="space-filler">
      </div>  

    </div>
  )
}

export default Contact;
