import React from 'react';

import './Card.css';

const Card = ({icon, title, subtitle,subtitle2, color}) => (
    <div className="card" style={{backgroundColor:color}}>
        <div className="icon">
            <img src={icon} alt="icon" height="100" />
        </div>
        <div className="cardText-container">
            <p className="title">{title}</p>
            <p className="subtitle">{subtitle}</p>
            <p className="subtitle">{subtitle2}</p>
        </div>
    </div>
);

export default Card;
