import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import './App.css';
import Navbar from './components/Navbar';
// import Title from './components/Title';
import Footer from './components/Footer';
import Home from './containers/Home';
import Software from './containers/Software';
import Covid19App from './containers/Covid19App';
import About from './containers/About';
import Contact from './containers/Contact';
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <title>Clinsys</title>
          <div className="body">
            <Switch>
              <Route exact path='/'component={Home} />
              <Route path='/home'component={Home} />
              <Route path='/software' component={Software} />
              <Route path='/covid19App' component={Covid19App} />
              <Route path='/about' component={About} />
              <Route path='/contact' component={Contact} />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;

// import logo from './logo.svg';
// import './App.css';
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
