import React from 'react';

import './List.css';

const List = ({ item1, item2, item3, item4, item5, item6,item7,item8,item9,item10,item11,item12 }) => (
    <div className="list-container">
        <ul className="list">
            <li>{item1}</li>
            {item2 ? <li>{item2}</li> : null}
            {item3 ? <li>{item3}</li> : null}
            {item4 ? <li>{item4}</li> : null}
            {item5 ? <li>{item5}</li> : null}
            {item6 ? <li>{item6}</li> : null}
            {item7 ? <li>{item7}</li> : null}
            {item8 ? <li>{item8}</li> : null}
            {item9 ? <li>{item9}</li> : null}
            {item10 ? <li>{item10}</li> : null}
            {item11 ? <li>{item11}</li> : null}
            {item12 ? <li>{item12}</li> : null}

        </ul>
    </div>
);

export default List;
