import React from 'react';
import FillerTop from '../components/FillerTop';

import './MedicalBackground.css';

const MedicalBackground = ({children}) => (
    <div className="contact-illustration">
        {children}
    </div>
);

export default MedicalBackground;
