import React from 'react';

import Section from '../components/Section';
import Banner from '../components/Banner';
import List from '../components/List';
import MedicalSliceBlue from '../Assets/MedicalSliceBlue.jpg';
import MedicalSliceDarkBlue from '../Assets/MedicalSliceDarkBlue.jpg';
import MedicalSliceGreen from '../Assets/MedicalSliceGreen.jpg';

// Vectors
import Clinic from '../Assets/ComputerSoftware/Clinic.jpg';
import Audiometry from '../Assets/ComputerSoftware/Audiometry.jpg';
import Pulmonary from '../Assets/ComputerSoftware/Pulmonary.jpg';
import JobExposures from '../Assets/ComputerSoftware/JobExposures.jpg';

import ChronicMonitoring from '../Assets/ComputerSoftware/ChronicMonitoring.jpg';
import Medication from '../Assets/ComputerSoftware/Medication.jpg';
import InjuryOnDuty from '../Assets/ComputerSoftware/InjuryOnDuty.jpg';
import Consultation from '../Assets/ComputerSoftware/Consultation.jpg';
import Covid19Module from '../Assets/ComputerSoftware/Covid19Module.jpg';

import Absenteeism from '../Assets/ComputerSoftware/Absenteeism.jpg';
import DiarySystem from '../Assets/ComputerSoftware/DiarySystem.jpg';
import SoftwareReports from '../Assets/ComputerSoftware/SoftwareReports.jpg';

// Screenshots
import ClinicTotals from '../Assets/Software/Screenshots/Clinic Totals Graph.png';
import OHR from '../Assets/Software/Screenshots/Occupational Health Graph.png';
import IOD from '../Assets/Software/Screenshots/IOD Graph.png';
import PHCR from '../Assets/Software/Screenshots/Primary Health Graph.png';
import CovidReport from '../Assets/Software/Screenshots/Covid Test Graph.png';
import FillerTop from '../components/FillerTop';
import './Software.css';

const Software = () => (
    <div className="software">
    <img src={Clinic} style={{ width: '100%' }} />
        <div style={{ width: '100%' }}>
            <h2 className="header-text">Computer Software Modules</h2>
            <Banner
                title='Occupational Health Modules'
            >
            <div className="bullet-points">
            <List
                item1="Pre-Employment, Pre-placement, Periodic, Bio-Monitoring & Exit Medicals"
                item2="Vitals, Urine Dipstix tests, Physical exam, Blood tests & Drug tests recorded"
                item3="Fit Certificates customisable with your logo"
                item4="Annexure 3 Fit Certificate for Construction Industry"
                item5="Tracking of Follow-Ups required, Expired Medicals"
            />
            </div>
            </Banner>
            <div className="software-cards">
                <Section
                    title="Audiometry Testing"
                    color="var(--blue-3)"
                    image={Audiometry}
                >
                <List
                    item1="PLH calculations & baseline audiograms"
                    item2="Milestone audiograms & STS calculations for mining industry"
                    item3="Graphical comparison"
                />
                </Section>
                <Section
                    title="Pulmonary Function Testing"
                    color="var(--blue-2)"
                    image={Pulmonary}
                    order2
                >
                <List
                    item1="Calculate category according to predicted range for patients age, height and gender."
                    item2="Pre- and Post-Bronchodilator tests catered for"
                />
                </Section>
                <Section
                    title="Job Exposures"
                    color="var(--green-1)"
                    image={JobExposures}
                >
                <List
                    item1="Job Exposures/Risks per Job Category and/or per Department"
                    item2="Job Specific Requirements"
                    item3="Personal Protective Equipment"
                    item4="Used on Annexure 3 Fit Certificate"
                />
                </Section>
            </div>
            <img src={MedicalSliceDarkBlue} className="medical-slice darkBlue-border" />
            <div className="software-cards">
                <Section
                    title="Primary Health Care Module"
                    color="var(--green-1)"
                    image={Consultation}
                    order2
                >
                <List
                    item1="Complaints, diagnoses, procedures are recorded using an International Primary Health Care (ICPC) Coding System"
                    item2="Drugs dispensed and recorded"
                    item3="Sick Certificate and Letter of Referral with your logo"
                    item4="History of patient visits at your finger tips"
                />
                </Section>
            </div>
            <div className="software-cards">
                <Section
                    title="Chronic Monitoring"
                    color="var(--blue-1)"
                    image={ChronicMonitoring}
                >
                <List
                    item1="Chronic disease register kept"
                    item2="Chronic diseases and allergies shown on all medical screens as a reminder"
                />
                </Section>
                <Section
                    title="Drug Stock Control"
                    color="var(--green-3)"
                    image={Medication}
                    order2
                >
                <List
                    item1="Accurate Drug Stock with purchase / dispensing / stock taking system"
                    item2="Drug Register Report can replace manual drug register"
                    item3="Report on all Drugs Used per month"
                />
                </Section>
                </div>
                <img src={MedicalSliceBlue} className="medical-slice blue-border" />
                <div className="software-cards">
                <Section
                    title="Injury/Disease on Duty Module"
                    color="var(--green-2)"
                    image={InjuryOnDuty}
                >
                    <List
                        item1="Record injuries sustained on duty or diseases contracted due to occupational hazards"
                        item2="Body Part Injured, Causes and Time Off recorded"
                        item3="Diagnoses, Procedures, Drugs Dispensed as in Primary Health Care"
                    />
                </Section>
                <Section
                    title="Covid-19 Module"
                    color="var(--blue-3)"
                    image={Covid19Module}
                    order2
                >
                    <List
                        item1="Identify employees vulnerable to Covid-19 due to Co-Morbidities"
                        item2="Symptom screening and probability scoring of likelihood of Covid"
                        item3="Covid Test results can be recorded"
                        item4="Diagnoses, Drugs Dispensed as in Primary Health Care"
                        item5="Recording of Covid-19 vaccinations"
                        item6="Reporting to NIOH"
                    />
                </Section>
            </div>
            <img src={MedicalSliceGreen} className="medical-slice green-border" />
            <div className="software-cards">
                <Section
                    title="Absenteeism"
                    color="var(--blue-2)"
                    image={Absenteeism}
                >
                    <List
                        item1="Record external Sick Certificates"
                        item2="Absenteeism calendar allows graphical illustration for employees with excessive sick leave"
                    />
                </Section>
                <Section
                    title="Diary/Booking System"
                    color="var(--green-3)"
                    image={DiarySystem}
                    order2
                >
                    <List
                        item1="All employees booked to come to clinic can be viewed for a day/week/month"
                        item2="Medicals due can be viewed/printed"
                        item3="Expired medicals can be viewed/printed"
                    />
                </Section>
            </div>
            <Banner
                title='Reports & Graphs'
            >
                <List
                    item1="Produce accurate monthly / annual reports with statistics reported by Company/ department / section / job title depending on level of detail required"
                    item2="User chooses which reports to produce"
                    item3="User has wide choice of options on graphs"
                />
            </Banner>
            <div className="reports-image">
                <img src={SoftwareReports}  />
            </div>
            <div className="software-cards">
                <Section
                    title="Clinic Totals Report "
                    color="var(--blue-3)"
                    image={ClinicTotals}
                >
                    <List
                        item1="Summary report on activity of clinic"
                        item2="Compare No of Medicals, IODs, Covid Visits & PHC Visits"
                    />
                </Section>
                <Section
                    title="Occupational Health Reports"
                    color="var(--blue-2)"
                    image={OHR}
                    order2
                >
                    <List
                        item1="Fit for Duty Statistics Report"
                        item2="Factors from Medical Surveillance  Report : any aspect of the medical e.g. Cholesterol / Gamma GT / ECG / Skin condition /Lead Results may be assessed"
                        item3="Lifestyle Report on high BP, BMI etc from Medical Surveillance"
                        item4="Comprehensive Audiometry Reports"
                        item5="Comprehensive Pulmonary Reports"
                    />
                </Section>
                <Section
                    title="Injury/Disease on Duty Reports"
                    color="var(--green-1)"
                    image={IOD}
                >
                    <List
                        item1="Body Part Injured Analysis"
                        item2="Diagnosis Category Analysis (eg Musculoskeletal, Eye, Skin etc)"
                        item3="Most common Diagnoses (eg Burn, Laceration, Sprain & Strain)"
                        item4="List of Injuries / Occupational Diseases"
                    />
                </Section>
                <Section
                    title="Primary Health Care Reports"
                    color="var(--blue-1)"
                    image={PHCR}
                    order2
                >
                    <List
                        item1="Diagnoses Category Analysis (e.g. Circulatory / Respiratory)"
                        item2="Most common specific diagnoses (eg Influenza / Conjunctivitis)"
                        item3="Complaints Analysis (e.g. headaches)"
                        item4="Procedures Analysis (e.g. counselling / referrals)"
                        item5="Chronic Disease Control Analysis"
                        item6="Comprehensive Lists on all of above"
                    />
                </Section>
                <Section
                    title="Covid-19 Reports"
                    color="var(--green-3)"
                    image={CovidReport}
                >
                    <List
                        item1="Stats on diagnoses (eg Covid Positive, Covid Possible)"
                        item2="No of employees in quarantine/self isolation"
                        item3="No of positive, negative and waiting results for Covid Tests"
                        item4="Comprehensive Lists on all of above"
                    />
                </Section>
            </div>
        </div>
    </div>
  );

export default Software;

// Other Features
 
//  	Absenteeism
// •	Record external Sick Certificates
// •	Absenteeism calendar allows graphical illustration for employees with excessive sick leave

// Diary/Booking System
// •	All employees booked to come to clinic can be viewed for a day/week/month
// •	Medicals due can be viewed/printed
// •	Expired medicals can be viewed/printed

// •	
// •	Comprehensive Audiometry Reports
// •	Comprehensive Pulmonary Reports

// Injury/Disease on Duty Reports :
// •	Body Part Injured Analysis
// •	Diagnosis Category Analysis (eg Musculoskeletal, Eye, Skin etc)
// •	Most common Diagnoses (eg Burn, Laceration, Sprain & Strain)
// •	List of Injuries / Occupational Diseases

// Primary Health Care Reports :
// •	Diagnoses Category Analysis (e.g. Circulatory / Respiratory)
// •	Most common specific diagnoses (eg Influenza / Conjunctivitis)
// •	Complaints Analysis (e.g. headaches)
// •	Procedures Analysis (e.g. counselling / referrals) 
// •	Chronic Disease Control Analysis
// •	Comprehensive Lists on all of above
 
// Covid-19 Reports :
// •	Stats on diagnoses (eg Covid Positive, Covid Possible)
// •	No of employees in quarantine/self isolation
// •	No of positive, negative and waiting results for Covid Tests
// •	Comprehensive Lists on all of above

//  	Absenteeism Reports :
// •	Absenteeism Stats
// •	Monday / Friday analysis


